import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription2 } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import SupportIconImage from "images/magnifying-glass-dollar-solid.svg";
import ShieldIconImage from "images/ChatGPT_logo.svg";
import CustomizeIconImage from "images/hubspot (1).svg";
import FastIconImage from "images/googleanalytics.svg";
import ReliableIconImage from "images/swipe-card (1).svg";
import SimpleIconImage from "images/meta-12360.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription2)`w-10/12 text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw` text-center rounded-full p-2 flex-shrink-0`}
    img {
      ${tw`w-10 h-10`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = "Amazing Features", subheading = "Features", description = "Our Web development services empower small businesses to seamlessly integrate with a wide range of applications, optimizing their return on investment and fueling exponential growth, all while minimizing costs." }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: ShieldIconImage,
      title: "AI Automation",
      description: "Seamlessly integrate your sales, marketing, and service requirements, along with exceptional Content Creation for Social Media."
    },
    { imageSrc: SupportIconImage, title: "SEO Optimization", description: "Maximize SEO strategies for unparalleled online success. Trust our expertise to boost your website's organic search rankings and leave competitors behind. " },
    { imageSrc: CustomizeIconImage, title: "CRM Integrations", description: "Supercharge your marketing, sales, and service teams by seamlessly integrating your data collection with your CRM." },
    { imageSrc: ReliableIconImage, title: "Payment Processing", description: "Enhance and accelerate your business requirements by seamlessly collecting payments online with stripe." },
    { imageSrc: FastIconImage, title: "Google Analytics", description: "Harness the immense potential of Google Ads and Analytics to drive the growth and profitability of your website." },
    { imageSrc: SimpleIconImage, title: "Virtual Reality", description: "Create a unique and immersive experience for your customers with our Virtual Reality services." }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer id="feature">
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
